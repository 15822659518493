/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { format } from 'date-fns';
import { Box, Typography, Card, CardContent, Chip } from '@mui/material';
import { useGetMailingHistoryEnabled } from '@hooks/useSendMailing';
import { getMailingListNameFromId } from '@utils/mailingLists';

const MailingHistory = ({
  ASIN,
  slug,
  promoCode
}: {
  ASIN?: string;
  slug?: string;
  promoCode?: string;
}) => {
  const { data: mailingHistory } = useGetMailingHistoryEnabled({
    ASIN,
    slug,
    promoCode
  });

  if (!mailingHistory || mailingHistory.length === 0) {
    return (
      <Box textAlign="center" py={3}>
        <Typography variant="body1" color="text.secondary">
          No mailing history available.
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      {mailingHistory.map((mailing) => (
        <Card
          key={mailing.id}
          variant="outlined"
          sx={{ mb: 2, borderColor: 'grey.300' }}
        >
          <CardContent>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Last sent:{' '}
              <Typography
                component="span"
                variant="body2"
                fontWeight="bold"
                color="text.primary"
              >
                {format(
                  new Date(mailing.createdAt),
                  "EEEE, MMMM d, yyyy 'at' h:mm a"
                )}
              </Typography>
            </Typography>

            <Typography variant="body2" color="text.secondary" gutterBottom>
              Mailed to list:{' '}
              <Typography
                component="span"
                variant="body2"
                fontWeight="bold"
                color="text.primary"
              >
                {getMailingListNameFromId(mailing.sentToList)}
              </Typography>
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Subject:{' '}
              <Typography
                component="span"
                variant="body2"
                fontWeight="bold"
                color="text.primary"
              >
                {mailing.subject}
              </Typography>
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
                mt: 1
              }}
            >
              {mailing.delivered && mailing.delivered !== 0 ? (
                <Chip
                  label={`Delivered: ${mailing.clicks}`}
                  color="secondary"
                  variant="outlined"
                  size="small"
                />
              ) : null}
              {mailing.uniqueOpens ? (
                <Chip
                  label={`Unique Opens: ${mailing.uniqueOpens}`}
                  color="secondary"
                  variant="outlined"
                  size="small"
                />
              ) : null}
              {mailing.clicks ? (
                <Chip
                  label={`Clicks: ${mailing.clicks}`}
                  color="secondary"
                  variant="outlined"
                  size="small"
                />
              ) : null}
              {mailing.uniqueOpenRate ? (
                <Chip
                  label={`Unique Open Rate: ${mailing.uniqueOpenRate.toFixed(
                    2
                  )}%`}
                  color="secondary"
                  variant="outlined"
                  size="small"
                />
              ) : null}
              {mailing.clickRate ? (
                <Chip
                  label={`Click Rate: ${mailing.clickRate.toFixed(2)}%`}
                  color="secondary"
                  variant="outlined"
                  size="small"
                />
              ) : null}
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default MailingHistory;
