import React, { useState, useRef } from 'react';
import ShareIcon from '@mui/icons-material/Share';
import {
  IconButton,
  Tooltip,
  Popover,
  Typography,
  TextField,
  useMediaQuery,
  ListItemIcon,
  Button,
  Box,
  MenuItem,
  ListItemText
} from '@mui/material';
import { useTheme } from '@mui/system';
import {
  isShareAPISupported,
  shareUrl,
  copyTextToClipboard
} from '@utils/index';
import { useSnackbar } from '@hooks/useSnackbar';
import { iconButtonHoverRaiseStyle } from '@utils/styles';

const ShareButton = ({
  title,
  url,
  type = 'button',
  onCloseCallback = () => {}
}: {
  title?: string;
  url?: string;
  type?: 'button' | 'menu';
  onCloseCallback?: () => void;
}) => {
  const { showMessage } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const titleRef = useRef(title || document.title);
  const urlRef = useRef(url || window.location.href);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const open = Boolean(anchorEl);
  const id = open ? 'share-popover' : undefined;

  const handleShareClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isShareAPISupported && isMobile) {
      if (!title || !url) {
        shareUrl();
      } else {
        shareUrl({ title, url });
      }
    } else {
      setAnchorEl(event.currentTarget as HTMLButtonElement);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (onCloseCallback) {
      onCloseCallback();
    }
  };

  const handleCopy = () => {
    copyTextToClipboard(`${titleRef.current}\n${urlRef.current}`);
    showMessage('Copied to clipboard!');
    handleClose();
  };

  const renderPopover = () => {
    return (
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        PaperProps={{
          sx: {
            maxWidth: '500px',
            width: '100%'
          }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="overline" gutterBottom>
            Copy the following to share:
          </Typography>
          <TextField
            value={`${titleRef.current}\n${urlRef.current}`}
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            sx={{ mb: 1 }}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              sx={{ mt: 1 }}
              onClick={handleCopy}
              size="small"
            >
              Copy
            </Button>
          </Box>
        </Box>
      </Popover>
    );
  };

  if (type === 'menu') {
    return (
      <>
        <MenuItem key="btn-share" onClick={handleShareClick}>
          <ListItemIcon>
            <ShareIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Share Deal</ListItemText>
        </MenuItem>
        {renderPopover()}
      </>
    );
  }

  return (
    <>
      <Tooltip title="Share this deal">
        <IconButton
          key="btn-share"
          size="small"
          aria-label="Share this deal"
          onClick={handleShareClick}
          sx={iconButtonHoverRaiseStyle}
        >
          <ShareIcon />
        </IconButton>
      </Tooltip>
      {renderPopover()}
    </>
  );
};

export default ShareButton;
